import React from 'react'
import Navbar from '../Navbar/Navbar'
import NavbarMobile from '../Navbar/NavbarMobile'
import Footer from '../Footer/Footer'
import FloatingButton from '../FloatingButton/FloatingButton'
import { getCookie } from 'cookies-next';

function MainLayout({
    moveToSection,
    children,
    setisShowBar,
    isShowBar,
    setnextTab,
    setnextTabMobile,
    textWa = "service",
    floatingHome = false
}) {
    const language = getCookie('NEXT_LOCALE') || 'id';


    return (
        <main className='relative flex h-full w-full flex-col justify-between'>
            <div className='lg:mx-auto w-full justify-center flex items-center'>
                <div className='hidden lg:mx-auto relative justify-center lg:flex w-screen'>
                    <Navbar lang={language} setnextTab={setnextTab} moveToSection={moveToSection} />
                </div>
                <div className='lg:hidden flex w-full'>
                    <NavbarMobile
                        lang={language}
                        setnextTab={setnextTabMobile}
                        setisShowBar={setisShowBar}
                        isShowBar={isShowBar}
                        moveToSection={moveToSection}
                    />
                </div>
            </div>
            <article className='flex flex-col top-16 relative lg:top-24 w-full mx-auto'>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                {children}
            </article>
            <div className='relative top-24 lg:top-32 mx-auto w-full justify-end flex items-center'>
                <Footer lang={language} setnextTab={setnextTab} moveToSection={moveToSection} />
            </div>
            <FloatingButton textWa={textWa} home={floatingHome} />
        </main>
    )
}

export default MainLayout