import React, { Fragment } from 'react'

const InputText = ({placeholder, id, name, type, value, onChange, className}) => {
  return (
    <Fragment>
        <input 
            name={name} 
            type={type} 
            id={id}
            value={value} 
            onChange={onChange} 
            placeholder={placeholder} 
            className={`${className} rounded-[8px] w-full border border-gray-cool-300 bg-base-white flex items-center px-[14px] py-2`} />
    </Fragment>
  )
}

export default InputText