export const contactUs = [
    {
        type: "section",
        list: {
            title: "home_contact_us",
            main: "contact_us_main",
            description: "contact_us_description"
        }
    },
    {
        type: "label-form",
        list: [
            {
                type: "name",
                text: "contact_us_input_placeholder_name"
            },
            {
                type: "email",
                text: "contact_us_input_placeholder_email"
            },
            {
                type: "phone",
                text: "No. Telp"
            },
            {
                type: "company_name",
                text: "company_name"
            },
            {
                type: "industry",
                text: "contact_us_input_placeholder_industry"
            },
            {
                type: "custom_industry",
                text: "contact_us_input_placeholder_other_industry"
            },
            {
                type: "message",
                text: "contact_us_input_placeholder_message_company"
            },
            {
                type: "button_submit",
                text: "contact_us_button_submit_placeholder"
            }
        ]
    },
]