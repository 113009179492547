import React, { Fragment } from 'react'

const Textarea = ({placeholder, name, id, type, value, onChange, className}) => {
  return (
    <Fragment>
        <textarea name={name} id={id} value={value} onChange={onChange} placeholder={placeholder} className={`${className} resize-none rounded-[8px] w-full border border-gray-cool-300 bg-base-white flex items-center px-[14px] py-2`} >
            {/* {value} */}
        </textarea>
    </Fragment>
  )
}

export default Textarea