'use client'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import logoLO from "@/assets/navbar/logo.png"
import { footerContent } from '@/helpers/content/id/footer'
import { montserratLight, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import mail from "@/assets/footers/mail.svg"
import wa from "@/assets/footers/wa.svg"
import fb from "@/assets/footers/fb.svg"
import linkedin from "@/assets/footers/linkedin.svg"
import tiktok from "@/assets/footers/tiktok.svg"
import ig from "@/assets/footers/ig.svg"
import kominfo from "@/assets/footers/kominfo.svg"
import kominfoText from "@/assets/footers/kominfo-text.svg"
import Link from 'next/link'
import indonesia from "@/assets/footers/indonesia.svg"
import singapore from "@/assets/footers/singapore.svg"
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/router'

export default function Footer({ moveToSection, setnextTab, lang }) {
    const { t } = useTranslation();
    let newProfile = footerContent.find(v => v.type == "profile")
    let newList = footerContent.find(v => v.type == "list")
    let newContact = footerContent.find(v => v.type == "contact")
    let newSosmed = footerContent.find(v => v.type == "sosmed")
    const [profile, setProfile] = useState(newProfile.detail)
    const [list, setlist] = useState(newList.list)
    const [contact, setcontact] = useState(newContact)
    const [sosmed, setsosmed] = useState(newSosmed)
    const router = useRouter()
    return (
        <footer className='flex relative h-auto w-full xl:mx-auto text-base lg:justify-between py-6 px-4 lg:px-8 flex-col '>
            <div className='max-w-[1280px] lg:container lg:mx-auto flex flex-col w-full h-full'>
                <div className='flex w-full h-full lg:flex-row flex-col'>
                    <div className='flex w-full full lg:w-1/2 flex-col'>
                        <Image src={logoLO} className='w-[154px] ' />
                        <div className={`flex flex-col text-gray-cool-500 leading-6 ${montserratNormal.className} justify-between`}>
                            <div className='flex py-5'>{t(profile.title)}</div>
                            <div className='flex space-y-3 w-full flex-col'>
                                {profile.location.map((val, index) =>
                                (
                                    <Link locale={lang} key={index} className="flex flex-wrap" href={val.maps} target='_blank'>
                                        <div className={`${montserratNormal.className} flex items-center text-gray-cool-700`}>
                                            <p className="pr-2">{val.company_name}</p>
                                            <div>
                                                <Image src={val.company_country == "id" ? indonesia : singapore} />
                                            </div>
                                        </div>
                                        <div className="relative w-full flex">
                                            <p className="text-gray-cool-500">{t(val.address)}</p>
                                        </div>

                                    </Link>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex md:py-0 py-8 shrink w-full h-full md:flex-row flex-grow md:flex-nowrap lg:flex-nowrap flex-wrap'>
                        {
                            list.map((val, index) => (
                                <div className='flex pt-8 w-full md:w-[30.5%] lg:w-1/3 lg:pl-8 lg:pr-0 md:pr-3 flex-col' key={index}>
                                    <div className={`${montserratSemibold.className} text-gray-cool-400 text-sm flex`}>{t(val.name)}</div>
                                    <div className='hidden lg:flex flex-col'>
                                        {
                                            val.type == "services" ? (
                                                <>
                                                    {
                                                        val.sub_list.map((v, i) => (
                                                            <p onClick={() => v.link ? window.open(v.link, "_self") : "#"} className={`${montserratNormal.className} hover:cursor-pointer text-gray-cool-700 py-2`} key={i}>{t(v.name)}</p>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        val.sub_list.map((v, i) => (
                                                            <p onClick={() => router.push(v.link)} className={`${montserratNormal.className} text-gray-cool-700 py-2 hover:cursor-pointer`} key={i}>{t(v.name)}</p>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className='flex lg:hidden flex-col'>
                                        {
                                            val.type == "services" ? (
                                                <>
                                                    {
                                                        val.sub_list.map((v, i) => (
                                                            <p onClick={() => v.link ? window.open(v.link, "_self") : "#"} className={`${montserratNormal.className} hover:cursor-pointer text-gray-cool-700 py-2`} key={i}>{t(v.name)}</p>
                                                        ))
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        val.sub_list.map((v, i) => (
                                                            <p onClick={() => router.push(v.link)} className={`${montserratNormal.className} text-gray-cool-700 hover:cursor-pointer py-2`} key={i}>{t(v.name)}</p>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        <div className='flex py-8 lg:pl-8 flex-col'>
                            <p className={`${montserratSemibold.className} text-gray-cool-400 text-sm`}>{t(contact.text)}</p>
                            {
                                contact.list.map((val, index) => (
                                    <div key={index} className='flex py-2 flex-col'>
                                        <Link locale={lang} href={val.link} target='_blank' className='flex items-center'>
                                            <Image src={val.type == "wa" ? wa : val.type == "mail" ? mail : ""} />
                                            <p className={`${montserratNormal.className} hover:cursor-pointer text-gray-cool-700 px-2`}>{val.text}</p>
                                        </Link>
                                    </div>
                                ))
                            }
                            <div className='flex py-8 flex-col'>
                                <p className={`flex ${montserratSemibold.className} text-gray-cool-400 text-sm`}>{t(sosmed.text)}</p>
                                <div className='flex space-x-4 py-2 flex-row' >
                                    {
                                        sosmed.list.map((val, index) => (
                                            <Link locale={lang} key={index} target='__blank' href={val.link}>
                                                <Image src={
                                                    val.type == "fb" ?
                                                        fb
                                                        : val.type == "ig" ?
                                                            ig
                                                            : val.type == "linkedin" ?
                                                                linkedin
                                                                : val.type == "tiktok" ?
                                                                    tiktok
                                                                    : ""
                                                } />
                                            </Link>
                                        ))
                                    }
                                </div>
                                <div className='flex flex-col'>
                                    <p className={`${montserratSemibold.className} text-gray-cool-400 text-sm  pt-8`}>Registered at</p>
                                    <div className='flex py-2 space-x-2 items-center'>
                                        <Image src={kominfo} />
                                        <Image src={kominfoText} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-8 flex border-t border-gray-cool-200 w-full text-gray-cool-500 ${montserratNormal.className} leading-6`}>
                    <p className='py-8'>{t('footer_copyright')}</p>
                </div>
            </div>
        </footer>
    )
}
