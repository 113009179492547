'use client'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { country } from '@/libs/country'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import "react-phone-input-2/lib/style.css";
import PhoneInput from 'react-phone-input-2';
import { montserratMedium, montserratNormal } from '@/assets/fonts/montserrat'

const PhoneNumberInput = ({country, error,value, id, onChange, placeholder, name}) => {
  const [focused, setfocused] = useState(false);

  return (
    <Fragment>
        <div className={`${montserratMedium.className}`}>
            <PhoneInput
                specialLabel={""}
                country={country ? country.toLowerCase() : "id"}
                defaultCountry={country ? country.toLowerCase() : "id"}
                value={value}
                name={name}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                limitMaxLength={true}
                inputClass={`${montserratNormal.className}`}
                inputStyle={{
                width: "100%",
                height: "45px",
                ":focus": {
                    borderColor: "#69e781",
                    boxShadow: "0px 0px 0px 1px #69e781",
                },
                border: `${error ? "1px solid #F04438" : ""}`,
                }}
                onFocus={(e) => {
                    console.log("ACS ", e), setfocused(true);
                }}
                onBlur={() => setfocused(false)}
                className={
                error && focused ? "focusError" : focused ? "focusDefault" : ""
                }
            />
        </div>
    </Fragment>
  )
}

export default PhoneNumberInput