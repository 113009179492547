import React from 'react'

const Button = ({disabled, id,onClick,className, children, variant = "solid", type = "button"}) => {
  const styleButton = variant == "outline" ? `border ` : ``
  const defaultStyle = "rounded-[8px] flex justify-center items-center py-3 px-6"
  return (
    <button id={id} disabled={disabled} onClick={onClick} type={type} className={`${className} ${defaultStyle} ${styleButton} `}>
        {children}
    </button>
  )
}

export default Button