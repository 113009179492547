export const footerContent = [
    {
        type: "copyright",
        description: "Copyright © 2024 PT Aryaseva Axlmos Kaya"
    },
    {
        type: "profile",
        detail: {
            title : "footer_detail_title",
            location: 
            [
                {
                    company_country: "id",
                    company_name: "PT Aryaseva Axlmos Kaya",
                    address: "footer_address_indonesia",
                    maps: "https://maps.app.goo.gl/jB7puvcCKug2cpTPA"
                },
                {
                    company_country: "sg",
                    company_name: "Ledgerowl Pte Ltd",
                    address: "160 Robinson Road, #14-04, Singapore 068914",
                    maps: "https://maps.app.goo.gl/mmycpi5nzbbtvJgQ6"
                }
            ]
        }
    },
    {
        type: "list",
        list: [
            {
                type: "company",
                name: "footer_list_company",
                sub_list: [
                    {
                        name: "footer_about_ledgerowl",
                        link: "https://blog.ledgerowl.com/about-ledgerowl/"
                    },
                    {
                        name: "footer_blogs",
                        link: "https://blog.ledgerowl.com/"
                    },
                    {
                        name: "footer_privacy_policy",
                        link: "/privacy"
                    },
                    {
                        name: "footer_terms_condition",
                        link: "/terms"
                    },
                ]
            },
            {  
                type: "services",
                name: "footer_list_services",
                sub_list: [
                    {
                        name: "service_bookeeping",
                        link: "/bookkeeping"
                    },
                    {
                        name: "tax",
                        link: "/tax"
                    },
                    {
                        name: "automated_recon",
                        link: "/reconciliation-automation"
                    },
                    {
                        name: "finance_operation",
                        link: "/finance-operation"
                    },
                    {
                        name: "business_legality",
                        link: "/legal"
                    },
                ]
            }
        ]
    },
    {
        type: "contact",
        text: "footer_contact",
        list: [
            {
                type: "wa",
                text: "+62 8128-7889-023",
                link: "https://api.whatsapp.com/send?phone=6281287889023&text=%20%20Halo,%20Saya%20ingin%20tanya%20tentang%20service%20di%20ledgerowl%20"
            },
            {
                type: "mail",
                text: "support@ledgerowl.com",
                link:"mailto:support@ledgerowl.com?subject=Requesting%20Professional%20Services%20-%20Assistance%20Needed&body=%20%20Hello,%20Ledgerowl.%0D%0A%20%20%0D%0A%20%20I%20am%20reaching%20out%20to%20inquire%20about%20your%20services.%0D%0A%20%20%0D%0A%20%20I%20would%20like%20to%20know%20more%20detailed%20information%20about%20your%20services,%20pricing,%20and%20the%20process%20of%20working%20together.%0D%0A%20%20%0D%0A%20%20Thank%20you%20for%20your%20time,%20and%20I%20look%20forward%20to%20hearing%20from%20you%20soon.%0D%0A%20%20%0D%0A%20%20Best%20regards,%0D%0A%20%20[Your%20Name]%0D%0A%20%20[Contact%20Information]"
            },
        ]
    },
    {
        type: "sosmed",
        text: "footer_social_media",
        list: [
            {
                type: "fb",
                link: "https://www.facebook.com/people/Ledgerowlcom/100063934906800/"
            },
            {
                type: "ig",
                link: "https://www.instagram.com/ledgerowl/"
            },
            {
                type: "linkedin",
                link: "https://www.linkedin.com/company/ledgerowl/mycompany/"
            },
            {
                type: "tiktok",
                link: "https://www.tiktok.com/@ledgerowl?lang=id-ID"
            },
        ]
    },
]